global.$ = global.jQuery = require('jquery');
global._ = require('underscore');
global.Modernizr = require('modernizr');

require('jquery-migrate');
require('jquery-shorten');
require('jquery-match-height');
require('jquery-validation');

require('bootstrap-sass');

require('bootstrap-datetime-picker');

require('redcurrents-bentokit/src/javascripts/buckle');
require('redcurrents-bentokit/src/javascripts/compat');
require('redcurrents-bentokit/src/javascripts/collapse-extended');
require('redcurrents-bentokit/src/javascripts/collapse-scrollto');
require('redcurrents-bentokit/src/javascripts/jquery.navbar-resizer');
require('redcurrents-bentokit/src/javascripts/offie');
require('redcurrents-bentokit/src/javascripts/front/_cover');
require('redcurrents-bentokit/src/javascripts/front/_event');
require('redcurrents-bentokit/src/javascripts/front/_publication_collapses');
require('redcurrents-bentokit/src/javascripts/front/_publication_responsive');

global.MatchHeightHelper = require('./scripts/matchheight-update');

require('./scripts/svgsprite');

require('./media--all.scss');

require('./scripts/main.js');

